.action-sheet-class {
/*     --background: #1d1010; */
  }

.action-sheet-class .action-sheet-container > :first-child{
    margin-top: auto;
    margin-bottom: 0;
}

.action-sheet-class .action-sheet-container > :last-child {
    margin-top: 0;
    margin-bottom: auto;
}

.fall-icon-css{
    color: red;
}


.fab-button-kamu{
    --box-shadow: none;
    --background-activated-opacity: 0.0;
    --background-focused-opacity: 0.0;
    margin-right: 0;
    margin-top: 0;
}

.fab-action-button{
/*     --background: var(--ion-color-light); */
    --opacity: 1;
}

.kamu-toolbar{
    --background: rgba(123,123,123,0);
}

.footer-toolbar{
    --padding-end: 0.5rem;
}

.kamu-event-item-footer{

}

.ios.kamu-event-item-footer{
/*     max-height: 3rem; */
    --background: none;
    margin-bottom: 1rem;
}

.kamu-event-item-header{
    --background: none;
}

.route-modal{
    --min-height: 85%;
}

.pose-modal{
    --min-height: 85%;
}

.bg-style {
    background: red;
}