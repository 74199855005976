.pose-style {
    display: flex;
    max-height: 350px;
    max-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    border: solid 3px;
    border-color: var(--ion-color-primary);
    border-radius: 5px;
}

.MuiFormControlLabel-label.Mui-disabled {
    color: var(--ion-color-medium-shade) !important;
}