/* ion-content {
    --padding-bottom: 0px;
    --padding-end: 0px;
    --padding-start: 0px;
    --padding-top: 0px;
  } */

ion-content.md{
    --padding-bottom: 1rem;
    --padding-end: 1rem;
    --padding-start: 1rem;
    --padding-top: 1rem;
  }