.container {
  text-align: center;
  position: relative;
  left: 0;
  right: 0;
  top: 50%;
  padding-top: 30%;
  place-content: center;
/*   transform: translateY(-50%); */
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

.containerIcon {
  width: 150px;
  height: 150px;
  display: flex;
  place-content: center;
  overflow: hidden;
 /*  background: rgba(255, 255, 255, 0.2); */
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

.item {
  width: 56%;
  overflow: visible;
  stroke: #fff;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
}